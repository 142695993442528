export default {
    addon: {
        background: '{form.field.background}',
        borderColor: '{form.field.border.color}',
        color: '{form.field.icon.color}',
        borderRadius: '{form.field.border.radius}',
        padding: '0.75rem',
        minWidth: '3rem'
    }
};
